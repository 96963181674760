import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";
export const WeightInsTable = (props) => {
    const weightIns = props.weightIns;
    const rows = [];
    const sortedWeightIns = Object.keys(weightIns).sort().reverse();
    for (let i = 0; i < sortedWeightIns.length; ++i) {
        const w = weightIns[sortedWeightIns[i]];
        if (w.weight == null)
            continue;
        rows.push((React.createElement(TableRow, { key: i },
            React.createElement(TableCell, null, w.date),
            React.createElement(TableCell, null, w.weight.toFixed(1)),
            React.createElement(TableCell, null, w.sevenDaysAverage.toFixed(1)),
            React.createElement(TableCell, null, w.thirtyDaysAverage.toFixed(1)))));
    }
    return (React.createElement(Table, { size: 'small' },
        React.createElement(TableHead, null,
            React.createElement(TableRow, { key: "header" },
                React.createElement(TableCell, null, "Date"),
                React.createElement(TableCell, null, "Weight"),
                React.createElement(TableCell, null, "7 Day Average"),
                React.createElement(TableCell, null, "30 Day Average"))),
        React.createElement(TableBody, null, rows)));
};
