import Box from '@mui/material/Box';
import React, { useState } from 'react';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import dateTimeUtils, { calculateDaysSince } from '../../../utils/DateTimeUtils';
import { Button, Grid, IconButton, TextField } from '@mui/material';
const START = dateTimeUtils('2025-03-26');
const storageKey = 'boushar.counters';
export default function () {
    var _a;
    // TODO remove counters an otherCounters from state and use data directly
    const [counters, setCounters] = React.useState({});
    const [data, setData] = useState({ version: 1, counters: {}, otherCounters: {} });
    const [value, setValue] = useState('');
    const [errorState, setErrorState] = useState(false);
    React.useEffect(() => {
        const fetchData = async () => {
            const data = await readData();
            if (data) {
                setData(data);
                setCounters(data.counters);
            }
        };
        fetchData();
    }, []);
    const handleInputChange = (e) => {
        var _a;
        setValue((_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.value);
        if (e.target.value.trim() === '') {
            setErrorState(true);
        }
        else {
            setErrorState(false);
        }
    };
    const addCounter = () => {
        if (value !== '') {
            const counterName = value;
            if (!counters.hasOwnProperty(counterName)) {
                setCounters(Object.assign(Object.assign({}, counters), { [counterName]: 0 }));
                saveData(Object.assign(Object.assign({}, data), { counters: Object.assign(Object.assign({}, counters), { [counterName]: 0 }) }));
            }
            setValue('');
        }
    };
    const decreaseCroissant = () => {
        const otherCounters = data['otherCounters'] || {};
        const croissant = otherCounters['Croissant'] || 0;
        const newOtherCounters = Object.assign(Object.assign({}, otherCounters), { 'Croissant': croissant - 1 });
        setData(Object.assign(Object.assign({}, data), { otherCounters: newOtherCounters }));
        saveData(Object.assign(Object.assign({}, data), { otherCounters: newOtherCounters }));
    };
    const increaseCroissant = () => {
        const otherCounters = data['otherCounters'] || {};
        const croissant = otherCounters['Croissant'] || 0;
        const newOtherCounters = Object.assign(Object.assign({}, otherCounters), { 'Croissant': croissant + 1 });
        setData(Object.assign(Object.assign({}, data), { otherCounters: newOtherCounters }));
        saveData(Object.assign(Object.assign({}, data), { otherCounters: newOtherCounters }));
    };
    return (React.createElement("div", null,
        React.createElement("div", null,
            React.createElement("h1", null, "Daily Counters"),
            React.createElement(Grid, { container: true, spacing: 1 },
                React.createElement(Grid, { item: true, xs: 8 },
                    React.createElement(TextField, { label: "Counter name", value: value, onChange: handleInputChange, error: errorState, helperText: errorState ? "Please enter some text" : "" }),
                    React.createElement("span", { style: { padding: '5px' } })),
                React.createElement(Grid, { item: true, xs: 4 },
                    React.createElement(Button, { variant: "contained", onClick: addCounter }, "Add Counter")),
                Object.entries(counters).sort(([, a], [, b]) => {
                    return b - a;
                }).map(([name, value]) => (React.createElement(React.Fragment, null,
                    (React.createElement(React.Fragment, null,
                        React.createElement(Grid, { item: true, xs: 4 }, name),
                        React.createElement(Grid, { item: true, xs: 4 },
                            " ",
                            calculateDaysSince(START.format('YYYY-MM-DD')) - value))),
                    React.createElement(Grid, { item: true, xs: 4 },
                        React.createElement(Button, { variant: "contained", onClick: () => {
                                const newCounters = Object.assign(Object.assign({}, counters), { [name]: value + 1 });
                                setCounters(newCounters);
                                setData(Object.assign(Object.assign({}, data), { counters: newCounters }));
                                saveData(Object.assign(Object.assign({}, data), { counters: newCounters }));
                            } }, "Done"))))))),
        React.createElement("div", null,
            React.createElement("h1", null, "Other Counters"),
            React.createElement(Box, { display: "grid", gridTemplateColumns: "auto auto auto", style: { alignItems: 'center' } },
                React.createElement(Box, null, "Croissant"),
                React.createElement(Box, null, (_a = data['otherCounters']) === null || _a === void 0 ? void 0 : _a['Croissant']),
                React.createElement(Box, null,
                    React.createElement(Box, null,
                        React.createElement(IconButton, { onClick: increaseCroissant },
                            React.createElement(ArrowDropUpIcon, null))),
                    React.createElement(Box, null,
                        React.createElement(IconButton, { onClick: decreaseCroissant },
                            React.createElement(ArrowDropDownIcon, null))))))));
}
const BARAM = 'boushar.baram';
const readData = async () => {
    const sLocalData = localStorage.getItem(storageKey);
    let localData = null;
    if (sLocalData != null) {
        localData = upgradeData(sLocalData);
    }
    const secretKey = localStorage.getItem(BARAM);
    try {
        if (secretKey !== null) {
            const response = await fetch("api/storage", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "X-BOUSHAR-BARAM": secretKey
                }
            });
            if (response.status === 200) {
                let serverData = await response.json();
                serverData = upgradeData(serverData);
                if (localData != null) {
                    const localCounters = localData.counters;
                    const serverCounters = serverData.counters;
                    const localOtherCounters = localData.otherCounters;
                    const serverOtherCounters = serverData.otherCounters;
                    const mergedData = Object.assign(Object.assign({}, localData), { counters: Object.assign(Object.assign({}, (typeof localCounters === 'object' && localCounters ? localCounters : {})), (typeof serverCounters === 'object' && serverCounters ? serverCounters : {})), otherCounters: Object.assign(Object.assign({}, (typeof localOtherCounters === 'object' && localOtherCounters ? localOtherCounters : {})), (typeof serverOtherCounters === 'object' && serverOtherCounters ? serverOtherCounters : {})) });
                    return mergedData;
                }
                return serverData;
            }
        }
    }
    catch (error) {
        console.error("Error fetching data from server:", error);
    }
    return localData;
};
const upgradeData = (sData) => {
    if (sData != null) {
        let data = sData;
        if (typeof sData === 'string') {
            data = JSON.parse(sData);
        }
        if (!data.hasOwnProperty('version')) {
            const counters = data;
            const otherCounters = {};
            const version = 1;
            return { version, counters, otherCounters };
        }
        else if (data.version === 1) {
            return data;
        }
    }
    return null;
};
const saveData = (data) => {
    localStorage.setItem(storageKey, JSON.stringify(data));
    const secretKey = localStorage.getItem(BARAM);
    if (secretKey !== null) {
        fetch("api/storage", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-BOUSHAR-BARAM": secretKey
            },
            body: JSON.stringify(data)
        });
    }
};
