import { clearSlot, replaceSlot } from "../../features/slots/slotsSlice";
import * as React from 'react';
import { useState } from "react";
import { useDispatch } from "react-redux";
import ClearIcon from "@mui/icons-material/Clear";
import { useAppContext } from "../../hooks/AppContext";
import { markTaskComplete, snoozeTask, markChecklistItemComplete } from "../../services/GraphService/GraphService";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { BucketView } from "./BucketView";
import IconButton from "@mui/material/IconButton";
import { ProgressOverlay } from "../../components/ProgressOverlay";
import Checkbox from "@mui/material/Checkbox";
import { CategoryProcessor } from '../processors/CategoryProcessor';
import { TaskDetailView } from "./TaskDetailView";
import LaunchIcon from '@mui/icons-material/Launch';
import { Snackbar, Typography } from "@mui/material";
import { calculateMinutesSince } from "../../utils/DateTimeUtils";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import TaskTitle from "./TaskTitle";
import { useSwipeable } from "react-swipeable";
const container = {
    display: "grid",
    gridTemplateColumns: "0.1fr 1fr 0.1fr",
    gridTemplateAreas: `
      'header header actions'
      'done title actions'
   `,
    width: '100%'
};
const header = {
    gridArea: 'header',
    fontSize: '0.7em',
    fontWeight: 'bold'
};
const done = {
    gridArea: 'done'
};
const actions = {
    color: "rgb(0,0,0,0.6)",
    textAlign: 'end',
    gridArea: 'actions',
};
const title = {
    gridArea: 'title',
};
export const SlotView = ({ slot, slots, index, updateCounter }) => {
    var _a;
    const dispatch = useDispatch();
    const app = useAppContext();
    const [bProgressOverlayOpen, setProgressOverlayOpen] = useState(false);
    const [bChecked, setChecked] = useState(false);
    const [bOpenDialog, setOpenDialog] = useState(false);
    const [bOpenSnack, setOpenSnack] = useState(false);
    const [snackTitle, setSnackTitle] = useState(''); // TODO use one snackbar in the parent component
    const swipeHandlers = useSwipeable({
        onSwipedRight: () => {
            dispatch(clearSlot(index));
        }
    });
    const isEmpty = slot.isEmpty();
    if (isEmpty) {
        return null;
    }
    if (bChecked === true && !slot.isComplete()) {
        setChecked(false);
    }
    const slotStyle = {
        display: "grid"
    };
    let backgroundColor = "#fff";
    if (index == 0) {
        slotStyle.borderLeft = "solid 2px #660066";
        backgroundColor = "rgb(102, 0, 102,0.25)";
    }
    if (index > 0 && index < 4) {
        slotStyle.borderLeft = "solid 2px #00cc44";
        backgroundColor = "rgb(0, 204, 68,0.25)";
    }
    if (index >= 4) {
        slotStyle.borderLeft = " solid 2px #996600";
        backgroundColor = "rgb(153, 102, 0,0.25)";
    }
    const toggleTask = (e) => {
        e.stopPropagation();
        if (!bChecked) {
            try {
                setProgressOverlayOpen(true);
                setChecked(true);
                if (slot.isCheckListItem()) {
                    markChecklistItemComplete(slot.getTaskId(), slot.getCheckListItemId(), app.authProvider);
                }
                else {
                    markTaskComplete(slot, app.authProvider);
                }
                slot.status = "completed";
                dispatch(replaceSlot(slot.object()));
            }
            finally {
                setProgressOverlayOpen(false);
                updateCounter();
            }
        }
    };
    const snoozeSlot = (e) => {
        e.stopPropagation();
        slot.status = 'postponed';
        dispatch(replaceSlot(slot.object()));
        CategoryProcessor.snoozeTask(app.authProvider, slot).then((daysToSnoozeBy) => {
            snoozeTask(slot, daysToSnoozeBy, app.authProvider).then((response) => {
                let title;
                // TODO remove commented code
                /*if (slot.type == 'Message') {
                   const task = Task.makeTaskFromFlaggedMessage(response)
                   title = task.getTitle()
                } else */
                {
                    title = response.title;
                }
                setSnackTitle(`Postponed: ${title}`);
                setOpenSnack(true);
            });
        });
    };
    const openDialog = () => {
        setOpenDialog(true);
    };
    const minutes = calculateMinutesSince(slot.added ? slot.added : "");
    const colorRed = Math.min(Math.floor(minutes / 5), 165);
    const color = `rgb(${colorRed}, 0 ,${165 - colorRed})`;
    const formatMinutes = () => {
        let returnVal;
        const hours = Math.floor(minutes / 60);
        if (hours >= 24 * 7) {
            returnVal = 'week+';
        }
        else if (hours >= 24) {
            returnVal = 'day+';
        }
        else if (hours === 0) {
            returnVal = `${minutes} minute`;
            if (minutes > 1)
                returnVal += 's';
        }
        else {
            const remainder = minutes % 60;
            returnVal = `${hours} hours and ${remainder} minutes`;
        }
        return returnVal;
    };
    let slotHeader = React.createElement("span", null, slot.headerTitle);
    if (((_a = slot.tid) === null || _a === void 0 ? void 0 : _a.listName) === 'flaggedEmails') {
        slotHeader = React.createElement(MailOutlineIcon, null);
    }
    const handleSnackClose = (_, reason) => {
        if (reason == 'clickaway') {
            return;
        }
        setOpenSnack(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Snackbar, { open: bOpenSnack, autoHideDuration: 6000, anchorOrigin: { vertical: 'top', horizontal: 'center' }, message: snackTitle, onClose: handleSnackClose }),
        React.createElement(ProgressOverlay, { open: bProgressOverlayOpen }),
        !isEmpty &&
            React.createElement("div", Object.assign({}, swipeHandlers, { style: container }),
                React.createElement("div", { style: header }, slotHeader),
                React.createElement("div", { style: done },
                    React.createElement(Checkbox, { onClick: toggleTask, checked: slot.isComplete() || bChecked })),
                React.createElement("div", { style: title, className: slot.status },
                    React.createElement("div", null,
                        React.createElement(TaskTitle, { title: slot.title ? slot.title : "" })),
                    React.createElement(Typography, { variant: "body2", gutterBottom: true, style: { color: color } }, formatMinutes())),
                React.createElement("div", { style: actions },
                    React.createElement(IconButton, { onClick: () => {
                            dispatch(clearSlot(index));
                            updateCounter();
                        } },
                        React.createElement(ClearIcon, null)),
                    React.createElement(IconButton, { onClick: (e) => { snoozeSlot(e); } },
                        React.createElement(ArrowForwardIcon, null)),
                    React.createElement(PunchOutDetails, { slot: slot, openDialog: openDialog }),
                    React.createElement(IconButton, null,
                        React.createElement(BucketView, { slot: slot })))),
        bOpenDialog && React.createElement(TaskDetailView, { open: bOpenDialog, closeDetailDialog: () => { setOpenDialog(false); }, slot: slot })));
};
function PunchOutDetails({ slot, openDialog }) {
    return React.createElement(IconButton, { onClick: openDialog },
        React.createElement(LaunchIcon, null));
}
