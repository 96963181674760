import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import React, { useState } from 'react';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import dateTimeUtils, { calculateDaysSince } from '../../../utils/DateTimeUtils';
import { Button, List, ListItem, ListItemText, TextField } from '@mui/material';
const START = dateTimeUtils('2024-12-30');
const storageKey = 'boushar.counters';
export default function () {
    const [counters, setCounters] = React.useState({});
    const [value, setValue] = useState('');
    const [errorState, setErrorState] = useState(false);
    React.useEffect(() => {
        const storedCounters = localStorage.getItem(storageKey);
        if (storedCounters) {
            setCounters(JSON.parse(storedCounters));
        }
    }, []);
    const handleInputChange = (e) => {
        var _a;
        setValue((_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.value);
        if (e.target.value.trim() === '') {
            setErrorState(true);
        }
        else {
            setErrorState(false);
        }
    };
    const addCounter = () => {
        if (value !== '') {
            const counterName = value;
            if (!counters.hasOwnProperty(counterName)) {
                setCounters(Object.assign(Object.assign({}, counters), { [counterName]: 0 }));
                localStorage.setItem(storageKey, JSON.stringify(Object.assign(Object.assign({}, counters), { [counterName]: 0 })));
            }
            setValue('');
        }
    };
    return (React.createElement("div", null,
        React.createElement("div", null,
            React.createElement("h1", null, "Daily Counters"),
            React.createElement(TextField, { label: "Counter name", value: value, onChange: handleInputChange, error: errorState, helperText: errorState ? "Please enter some text" : "" }),
            React.createElement("span", { style: { padding: '5px' } }),
            React.createElement(Button, { variant: "contained", onClick: addCounter }, "Add Counter"),
            React.createElement(List, { sx: { width: '100%', maxWidth: 360, bgcolor: 'background.paper' } }, Object.entries(counters).map(([name, value]) => (React.createElement(ListItem, null,
                React.createElement(ListItemText, { primary: (React.createElement("span", null,
                        name,
                        ": ",
                        calculateDaysSince(START.format('YYYY-MM-DD')) - value)) }),
                React.createElement(Button, { variant: "contained", onClick: () => {
                        const newCounters = Object.assign(Object.assign({}, counters), { [name]: value + 1 });
                        setCounters(newCounters);
                        localStorage.setItem(storageKey, JSON.stringify(newCounters));
                    } }, "Done")))))),
        false && React.createElement("div", null,
            React.createElement("h1", null, "Other Counters"),
            React.createElement(Box, { display: "grid", gridTemplateColumns: "auto auto auto", style: { alignItems: 'center' } },
                React.createElement(Box, null, "Fasting"),
                React.createElement(Box, null,
                    React.createElement(OutlinedInput, { placeholder: "Hours" })),
                React.createElement(Box, null,
                    React.createElement(Box, null,
                        React.createElement(ArrowDropUpIcon, null)),
                    React.createElement(Box, null,
                        React.createElement(ArrowDropDownIcon, null))),
                React.createElement(Box, null, "Credit"),
                React.createElement(Box, null,
                    React.createElement(OutlinedInput, { placeholder: "Hours" })),
                React.createElement(Box, null,
                    React.createElement(Box, null,
                        React.createElement(ArrowDropUpIcon, null)),
                    React.createElement(Box, null,
                        React.createElement(ArrowDropDownIcon, null))),
                React.createElement(Box, null, "Croissant"),
                React.createElement(Box, null,
                    React.createElement(OutlinedInput, { placeholder: "Hours" })),
                React.createElement(Box, null,
                    React.createElement(Box, null,
                        React.createElement(ArrowDropUpIcon, null)),
                    React.createElement(Box, null,
                        React.createElement(ArrowDropDownIcon, null)))))));
}
