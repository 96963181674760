import moment from "moment";
import dateTimeUtils from "../../utils/DateTimeUtils";
export class Task {
    getDueDateTime() {
        return this.todoTask.dueDateTime;
    }
    getReminderDateTime() {
        return this.todoTask.reminderDateTime;
    }
    static compareFn(a, b) {
        if (a.getStartDateTime().isSame(b.getStartDateTime())) {
            return 0;
        }
        if (a.getStartDateTime().isBefore(b.getStartDateTime()))
            return -1;
        else
            return 1;
    }
    constructor(todoTask, listName) {
        this.todoTask = todoTask;
        this.type = 'TodoTask';
        this.listName = listName;
    }
    static makeTask(t, item, listName) {
        if (item == null) {
            const task = new Task(t, listName);
            return task;
        }
        const task = new Item(item, t, listName);
        return task;
    }
    /*static makeTaskFromFlaggedMessage(m:any):Message {
       const task = new Message(m)
       return task
    }*/
    hasChecklistItems() {
        return this.todoTask.checklistItems != undefined;
    }
    getHeaderTitle() {
        return null;
    }
    getItems() {
        if (this.todoTask.checklistItems === undefined) {
            return [];
        }
        return this.todoTask.checklistItems;
    }
    getTodoTask() {
        return this.todoTask;
    }
    getTitle() {
        return this.todoTask.title;
    }
    getStatus() {
        return this.todoTask.status;
    }
    getBody() {
        return this.todoTask.body;
    }
    getCategories() {
        return this.todoTask.categories;
    }
    getCategory() {
        let ret = undefined;
        if (this.getCategories().find((c) => c == 'one')) {
            ret = 'one';
        }
        else if (this.getCategories().find((c) => c == 'three')) {
            ret = 'three';
        }
        else if (this.getCategories().find((c) => c == 'five')) {
            ret = 'five';
        }
        return ret;
    }
    getCheckListItemId() {
        return "";
    }
    getId() {
        return { taskId: this.todoTask.id, listName: this.listName };
    }
    getStartDateTime() {
        let returnMoment = moment(0);
        if (this.todoTask.dueDateTime != null) {
            returnMoment = moment.tz(this.todoTask.dueDateTime.dateTime, this.todoTask.dueDateTime.timeZone ? this.todoTask.dueDateTime.timeZone : "UTC");
        }
        else if (this.todoTask.reminderDateTime != null) {
            returnMoment = moment.tz(this.todoTask.reminderDateTime.dateTime, this.todoTask.reminderDateTime.timeZone ? this.todoTask.reminderDateTime.timeZone : "UTC");
        }
        return returnMoment.local();
    }
    getWebLink() {
        return undefined;
    }
    isInTheFuture() {
        if (this.todoTask.dueDateTime != undefined &&
            dateTimeUtils(this.todoTask.dueDateTime).isAfterNow()) {
            return true;
        }
        if (this.todoTask.reminderDateTime != undefined &&
            dateTimeUtils(this.todoTask.reminderDateTime).isAfterNow()) {
            return true;
        }
        return false;
    }
    diff(t) {
        return this.getStartDateTime().diff(t.getStartDateTime(), "days");
    }
}
class Item extends Task {
    constructor(item, todo, listName) {
        super(todo, listName);
        this.item = item;
        this.type = 'ChildItem';
    }
    getCheckListItemId() {
        return this.item.id;
    }
    getStatus() {
        if (this.item.isChecked)
            return "completed";
        else
            return "notStarted";
    }
    getTitle() {
        return this.item.displayName;
    }
    getTodoTask() {
        return this.todoTask;
    }
    getHeaderTitle() {
        return this.todoTask.title;
    }
}
/*class Message extends Task {
   title:string
   message
   
   constructor(m:any) {
      super({})
      this.title = m.subject
      this.message = m
      this.type = 'Message'
   }

   getCategories():any[] {
      return []
   }

   getDueDateTime() {
      const flag = this.message.flag
      const dueDateTime = flag?.dueDateTime
      return dueDateTime
   }

   getId() {
      return this.message.id
   }

   getStatus():TaskStatus {
      if (this.message.flag.flagStatus === 'notFlagged')
         return 'completed'

      return 'notStarted'
   }

   getStartDateTime(): moment.Moment {
       return dateTimeUtils(this.getDueDateTime()).moment
   }

   getTitle() {
      return this.title
   }

   getWebLink():string {
      return this.message.webLink
   }

   isInTheFuture(): boolean {
      if(dateTimeUtils(this.getDueDateTime()).isAfterNow()) {
         return true
      }
      return false
   }
}*/ 
