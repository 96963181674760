import { calculateSecondsSince } from "../../utils/DateTimeUtils";
import Logger from "../../utils/Logger";
class Entry {
    constructor(task) {
        this.task = task;
        this.created = new Date();
    }
}
export class GraphCache {
    constructor() {
        this.entries = {};
    }
    static getInstance() {
        if (!GraphCache.instance) {
            GraphCache.instance = new GraphCache();
        }
        return GraphCache.instance;
    }
    async cleanup() {
        for (let [key, value] of Object.entries(this.entries)) {
            if (calculateSecondsSince(value.created) > 10) {
                delete this.entries[key];
            }
        }
    }
    get(taskId) {
        if (this.entries[taskId] == undefined) {
            Logger.error(`Logic Error: Task id ${taskId}not in cache`);
        }
        return this.entries[taskId].task;
    }
    isTaskInCache(taskId) {
        if (this.entries[taskId] != undefined && calculateSecondsSince(this.entries[taskId].created) < 10) {
            return true;
        }
        else {
            return false;
        }
    }
    put(taskId, task) {
        if (task == null) {
            throw new Error("Logic Error: task is null");
        }
        this.entries[taskId] = new Entry(task);
    }
}
