import moment from "moment";
import { getTask, updateBucket } from "../../services/GraphService/GraphService";
import dateTimeUtils from "../../utils/DateTimeUtils";
import { StorageUtils } from "../../utils/StorageUtils";
import { Bucket } from "../model/Bucket";
import { replaceSlot } from "../../features/slots/slotsSlice";
export class CategoryProcessor {
    constructor(dispatch, authProvider, slots, todos) {
        this.authProvider = authProvider;
        this.slots = slots;
        this.todos = todos;
        this.dispatch = dispatch;
    }
    isInTheFuture(todo) {
        if (todo.dueDateTime != undefined && dateTimeUtils(todo.dueDateTime).isAfterNow()) {
            return true;
        }
        if (todo.reminderDateTime != undefined && dateTimeUtils(todo.reminderDateTime).isAfterNow()) {
            return true;
        }
        return false;
    }
    async process(begin, end) {
        const aSlots = this.slots.getSlots();
        for (let i = begin; i <= end; ++i) {
            const slot = aSlots[i];
            if (!slot.isEmpty()) {
                const todo = await getTask(slot.tid, slot.type, this.authProvider);
                if (todo != null) {
                    const updated = this.slots.updateSlot(slot.getIndex(), todo);
                    if (updated)
                        this.dispatch(replaceSlot(this.slots.getSlots()[slot.getIndex()].object()));
                }
            }
            else { // if slot is empty
                this.slots.resetSlot(slot.getIndex());
                const todos = this.todos;
                if (todos != null) {
                    const list = todos.filter((t) => {
                        return t.getStatus() != 'completed' &&
                            (t.getCategories().find((c) => (c === this.getCategory())) != undefined);
                    });
                    const currentList = list.filter((t) => {
                        return !this.isInTheFuture(t.getTodoTask());
                    });
                    let bAddedToBoard = false;
                    for (let i = 0; i < currentList.length; ++i) {
                        let b;
                        if (this.slots.isTaskOnBoard(currentList[i], b)) {
                            continue;
                        }
                        else {
                            this.slots.fillSlot(slot.getIndex(), currentList[i]);
                            this.dispatch(replaceSlot(this.slots.getSlots()[slot.getIndex()].object()));
                            bAddedToBoard = true;
                            break;
                        }
                    }
                    if (!bAddedToBoard) {
                        // that means there are no current category tasks.
                        // find the non-categorized tasks
                        const noCategoryList = todos.filter((t) => {
                            return t.getStatus() != 'completed' &&
                                (t.getCategories().length == 0 ||
                                    t.getCategories().find((c) => (c != 'one' && c != 'three' && c != 'five')) != undefined);
                        });
                        const currentNoCategoryList = noCategoryList.filter((t) => {
                            return !t.isInTheFuture();
                        });
                        for (let i = 0; i < currentNoCategoryList.length; ++i) {
                            let b;
                            if (this.slots.isTaskOnBoard(currentNoCategoryList[i], b)) {
                                continue;
                            }
                            else {
                                this.slots.fillSlot(slot.getIndex(), currentNoCategoryList[i]);
                                const s = this.slots.getSlots()[slot.getIndex()];
                                this.dispatch(replaceSlot(s.object()));
                                bAddedToBoard = true;
                                // update category on the task
                                switch (slot.getIndex()) {
                                    case 0:
                                        updateBucket(s, "one", this.authProvider);
                                        break;
                                    case 1:
                                    case 2:
                                    case 3:
                                        updateBucket(s, "three", this.authProvider);
                                        break;
                                    case 4:
                                    case 5:
                                    case 6:
                                    case 7:
                                    case 8:
                                        updateBucket(s, "five", this.authProvider);
                                }
                                break;
                            }
                        }
                        // end of the function
                    }
                }
            }
        }
    }
    getTodoTaskDateTime(t) {
        let m = moment(0);
        if (t.dueDateTime != null) {
            m = moment.tz(t.dueDateTime.dateTime, t.dueDateTime.timeZone ? t.dueDateTime.timeZone : "UTC");
        }
        else if (t.reminderDateTime != null) {
            m = moment.tz(t.reminderDateTime.dateTime, t.reminderDateTime.timeZone ? t.reminderDateTime.timeZone : "UTC");
        }
        return m;
    }
    static async snoozeTask(authProvider, slot) {
        let queue = null;
        switch (slot.getBucket()) {
            case Bucket.ONE:
                queue = await StorageUtils.readQueueOne(authProvider);
                break;
            case Bucket.THREE:
                queue = await StorageUtils.readQueueThree(authProvider);
                break;
            case Bucket.FIVE:
                queue = await StorageUtils.readQueueFive(authProvider);
                break;
        }
        if (queue != null) {
            // find the task
            let task;
            for (let i = 0; i < queue.length; ++i) {
                if (queue[i].getTodoTask().id == slot.tid) {
                    task = queue[i];
                    break;
                }
            }
            const dailyList = createDailyList(queue);
            if (slot.getBucket() === Bucket.ONE) {
                return calculateDaysToSnooze(dailyList, moment(), 1);
            }
            else if (slot.getBucket() == Bucket.THREE) {
                return calculateDaysToSnooze(dailyList, moment(), 3);
            }
            else if (slot.getBucket() == Bucket.FIVE) {
                return calculateDaysToSnooze(dailyList, moment(), 5);
            }
        }
        return 1;
    }
}
function createDailyList(queue) {
    const dailyList = {};
    for (let i = 0; i < queue.length; ++i) {
        const id = queue[i].getStartDateTime().format('MDY');
        if (dailyList[id] == undefined) {
            dailyList[id] = [];
        }
        dailyList[id].push(queue[i]);
    }
    return dailyList;
}
function calculateDaysToSnooze(dailyList, day, steps) {
    console.log(dailyList);
    const nextDay = day.add(1, 'day').startOf('day');
    const nextDayId = nextDay.format('MDY');
    if (dailyList[nextDayId] != undefined && dailyList[nextDayId].length >= steps) {
        return calculateDaysToSnooze(dailyList, nextDay, steps);
    }
    const now = moment().startOf('day');
    console.log(nextDay);
    console.log(now);
    let days = nextDay.diff(now, 'day');
    console.log(days);
    if (days == 0) {
        days += 1;
    }
    return days;
}
